import authHeader from "@/services/auth/AuthHeader";
import axios from "axios";

class SettingsService {
    getOpeningHours(locationId){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/settings/getOpeningHours' + '?merchant=' + user.merchant + '&locationId=' + locationId;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    editOpeningHours(openingHoursDocument){
        let url = '/api/settings/editOpeningHours';
        let basicAuth = authHeader();
        return axios.post(url, openingHoursDocument,{
            headers: basicAuth });
    }

    getDeliveryDocument(locationId){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/settings/getDeliveryDocument' + '?merchant=' + user.merchant + '&locationId=' + locationId;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    editDeliveryDocument(deliveryDocument){
        let url = '/api/settings/editDeliveryDocument';
        let basicAuth = authHeader();
        return axios.post(url, deliveryDocument,{
            headers: basicAuth });
    }

    getClosingDays(){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/settings/getClosingDays' + '?merchant=' + user.merchant;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    deleteClosingDay(closingDay){
        let url = '/api/settings/deleteClosingDay';
        let basicAuth = authHeader();
        return axios.post(url, closingDay,{
            headers: basicAuth });
    }

    createClosingDays(closingDays){
        let url = '/api/settings/createClosingDays';
        let basicAuth = authHeader();
        return axios.post(url, closingDays,{
            headers: basicAuth });
    }

    openTakeaway(merchant, locationId, fromDate, toDate){
        let payload = {
            merchant: merchant,
            locationId: locationId,
            fromDate: fromDate,
            toDate: toDate
        };
        let url = '/api/settings/deleteClosingDaysByRange';
        let basicAuth = authHeader();
        return axios.post(url, payload, {
            headers: basicAuth });
    }

    changeEarliestPickupTime(merchant, locationId, earliestPickupTime){
        let payload = {
            merchant: merchant,
            locationId: locationId,
            earliestPickupTime: earliestPickupTime
        };
        let url = '/api/settings/changeEarliestPickupTime';
        let basicAuth = authHeader();
        return axios.post(url, payload, {
            headers: basicAuth });
    }

    closeDishes(merchant, locationId){
        let payload = {
            merchant: merchant,
            locationId: locationId
        };
        let url = '/api/settings/closeDishes';
        let basicAuth = authHeader();
        return axios.post(url, payload, {
            headers: basicAuth });
    }

    openDishes(merchant, locationId){
        let payload = {
            merchant: merchant,
            locationId: locationId
        };
        let url = '/api/settings/openDishes';
        let basicAuth = authHeader();
        return axios.post(url, payload, {
            headers: basicAuth });
    }

    getVat(){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/settings/getVat' + '?merchant=' + user.merchant;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    editVat(vat){
        let url = '/api/settings/editVat';
        let basicAuth = authHeader();
        return axios.post(url, vat,{
            headers: basicAuth });
    }

    getCompany(){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/settings/getCompany' + '?merchant=' + user.merchant;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    editCompany(company){
        let url = '/api/settings/editCompany';
        let basicAuth = authHeader();
        return axios.post(url, company,{
            headers: basicAuth });
    }

    getSwedbankPayAccount(locationId){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/settings/getSwedbankPayAccount' + '?merchant=' + user.merchant  + '&locationId=' + locationId;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    editSwedbankPayAccount(swedbankPayAccount){
        let url = '/api/settings/editSwedbankPayAccount';
        let basicAuth = authHeader();
        return axios.post(url, swedbankPayAccount,{
            headers: basicAuth });
    }

    getDinteroAccount(locationId){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/settings/getDinteroAccount' + '?merchant=' + user.merchant  + '&locationId=' + locationId;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    editDinteroAccount(dinteroAccount){
        let url = '/api/settings/editDinteroAccount';
        let basicAuth = authHeader();
        return axios.post(url, dinteroAccount,{
            headers: basicAuth });
    }

    getMerchantSetting(){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/settings/getMerchantSetting' + '?merchant=' + user.merchant;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    editMerchantSetting(merchantSetting){
        let url = '/api/settings/editMerchantSetting';
        let basicAuth = authHeader();
        return axios.post(url, merchantSetting,{
            headers: basicAuth });
    }
}

export default new SettingsService();
