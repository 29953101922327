import authHeader from "@/services/auth/AuthHeader";
import axios from "axios";
import MathUtil from "@/util/MathUtil";

class DinteroService {
    createAmountForDintero(amount){
        let amountForDintero = MathUtil.displayTwoDecimals(amount).replace(',', ''); //Just remove comma
        return parseInt(amountForDintero);
    }

    createItemDescription(cartItem){
        var description = cartItem.itemName;
        if(cartItem.selectedItemSubselection){
            description += ', ' + cartItem.selectedItemSubselection;
        }
        if(cartItem.spicyLevel){
            description += ', ' + cartItem.spicyLevel;
        }
        if(cartItem.comment){
            description += ', ' + cartItem.comment;
        }
        return description;
    }

    createDinteroItems(cartItems){
        var dinteroItems = [];
        for (var i = 0; i < cartItems.length; i++) {
            var cartItem = cartItems[i];
            let lineId = i + 1;

            var dinteroItem = {};
            dinteroItem.id = '...';
            dinteroItem.line_id = lineId.toString();

            dinteroItem.quantity = cartItem.count;
            dinteroItem.amount = this.createAmountForDintero(parseFloat(cartItem.price) * cartItem.count);
            dinteroItem.description = this.createItemDescription(cartItem);
            dinteroItems.push(dinteroItem);
        }
        //console.info('orderItems: ' + JSON.stringify(dinteroItems));
        return dinteroItems;
    }

    refund(transactionId, amount, cartItems, refundedTipsAmount, orderDocumentId){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/payment/softpayRefund';
        let basicAuth = authHeader();
        return axios.post(url,
            {
                merchant: user.merchant,
                transactionId: transactionId,
                amount: this.createAmountForDintero(amount),
                dinteroItems: this.createDinteroItems(cartItems),
                cartItems: cartItems,
                refundedTipsAmount : refundedTipsAmount,
                orderDocumentId: orderDocumentId
            },
            {headers: basicAuth });
    }
}

export default new DinteroService();
