<template>
  <div class="container">
      <LoadingSpinner :loading="loading"> </LoadingSpinner>

      <div v-if="!loading" class="mt-4 mb-4" style="max-width:560px">
        <h3>Refusjon </h3>
        <div class="mt-4">{{getOrderTypeText()}} - Ordre #{{order.orderId}}</div>

        <div v-if="step == 1">
          <div class="mt-5 mb-0">
            <button type="button" class="bo_btn" @click="selectAllOrRemoveAll"> {{selectAllOrRemoveAllButtonText()}} </button>
          </div>
          <hr class="mb-0"/>
          <div v-for="refundCartItem in refundCartItems" :key="refundCartItem.id" :id="refundCartItem.id" class="refundCartItem">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <input type="checkbox" :name="refundCartItem.id" :value="refundCartItem.id" :checked="selectedCartItemsContains(refundCartItem)" @click="updateSelectedCartItems(refundCartItem)" class="checkbox" />
                <div>
                  <div>
                    {{ refundCartItem.itemName }}
                  </div>
                  <div v-if="refundCartItem.itemSubselection">
                    {{ refundCartItem.itemSubselection.name }}
                  </div>
                  <div v-if="refundCartItem.spicyLevel != ''">
                    {{ refundCartItem.spicyLevel}}
                  </div>
                  <div v-if="refundCartItem.comment != ''">
                    {{ refundCartItem.comment }}
                  </div>
                </div>
              </div>
              <div>
                {{ displayTwoDecimals(getPriceWithVat(refundCartItem)) }}
              </div>
            </div>
          </div>
          <div v-if="order.tipsAmount && order.tipsAmount > 0" class="mt-2">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <input type="checkbox" :name="'tips_' + order.orderId" :value="'tips_' + order.orderId"
                       :checked="refundedTipsAmount > 0" @click="updateRefundedTipsAmount()"
                       class="checkbox" />
                <div> Tips </div>
              </div>
              <div>
                {{ displayTwoDecimals(order.tipsAmount) }}
              </div>
            </div>
          </div>

          <div class="bo_savebtn_container mt-5 mb-5">
            <button type="button" class="bo_btn" @click="step=2" :disabled="disableNextBtn()">Neste</button>
          </div>
        </div>

        <div v-if="step == 2">
          <hr class="mb-0"/>
          <div v-for="selectedCartItem in selectedCartItems" :key="selectedCartItem.id" :id="selectedCartItem.id" class="refundCartItem">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div>
                  <div>
                    -1 x {{selectedCartItem.itemName}}
                  </div>
                  <div v-if="selectedCartItem.itemSubselection">
                    {{ selectedCartItem.itemSubselection.name }}
                  </div>
                  <div v-if="selectedCartItem.spicyLevel != ''">
                    {{ selectedCartItem.spicyLevel}}
                  </div>
                  <div v-if="selectedCartItem.comment != ''">
                    {{ selectedCartItem.comment }}
                  </div>
                </div>
              </div>
              <div>
                -{{ displayTwoDecimals(getPriceWithVat(selectedCartItem)) }}
              </div>
            </div>
          </div>
          <div v-if="refundedTipsAmount > 0" class="mt-2 mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div> -Tips </div>
              </div>
              <div>
                -{{ displayTwoDecimals(refundedTipsAmount) }}
              </div>
            </div>
          </div>

          <div class="d-flex align-items-center justify-content-between">
            <div>
              <span style="font-weight: bold;">Totalt</span>
            </div>
            <div>
              <span style="font-weight: bold;color: red">-{{ displayTwoDecimals(getTotalRefundAmount()) }}</span>
            </div>
          </div>

          <div class="bo_savebtn_container mt-5 mb-5">
            <div class="d-flex align-items-center justify-content-between">
              <div>
                <button type="button" class="bo_btn" @click="step=1">Tilbake</button>
              </div>
              <div>
                <button type="button" class="bo_btn" @click="refund">Refunder</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ConfirmDialogue ref="confirmDialogue"></ConfirmDialogue>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/other/LoadingSpinner";
import OrderService from "@/services/order/OrderService";
import OrderUtil from "@/util/OrderUtil";
import VatUtil from "@/util/VatUtil";
import ConfirmDialogue from "@/components/other/ConfirmDialogue";
import SoftpayService from "@/services/payment/SoftpayService";
import MathUtil from "@/util/MathUtil";

export default {
  components: {LoadingSpinner, ConfirmDialogue},
  data() {
    return {
      loading: true,
      step: 1,

      vat: {},
      orderDocumentId: '',
      order: {
        type: "order",
        merchant: '',
        orderId: '',
        orderType: '',
        orderStatus: '',
        totalAmount: 0,
        description: '',
        pickupOrDeliveryTime: '',
        mobileNumber: '',
        name: '',
        nameOnDoorbell: '',
        street: '',
        houseNumber: '',
        floor: '',
        zipCode: '',
        city: '',
        orderedTime: '',
        modifiedTime: '',
        servedBy: '',
        channel: '',
        payments: [],
        vats: [],
        totalVat: 0,
        cartItemsJson: []
      },
      refundCartItems: [],
      refundedTipsAmount : 0,
      selectedCartItems: []
    };
  },
  mounted() {
    document.getElementById('nav').hidden = true;
    document.getElementById('footer').hidden = true;
    document.getElementById('scrollTopBtn').hidden = true;

    //Define the method to handle messages from native Android app code
    window.receiveMessageFromNative = (message) => {
      if(message == 'REFUND_SUCCESS'){
          this.loading = true;
          this.refundOrder();
      } else {
          this.loading = false;
          this.showRefundFailedMessage();
      }
    };

    this.orderDocumentId = this.$route.query.orderDocumentId;
    OrderService.getDataForOrderDetailGui(this.orderDocumentId).then(
        (response) => {
          let dataForGui = response.data;
          //console.log('dataForGui: ' + JSON.stringify(dataForGui));
          this.order = dataForGui.order;
          this.vat = dataForGui.vat;
          this.refundCartItems = this.getRefundCartItems();
          this.loading = false;
        },
        (error) => {
          console.log(error);
        }
    );
  },
  methods:{
    getOnlinePaymentProvider(){
      let onlinePaymentProvider = OrderUtil.getOnlinePaymentProvider(this.order.payments);
      return onlinePaymentProvider;
    },

    getRefundCartItems(){
      let refundCartItems = [];
      let cartItems = this.order.cartItems;
      console.log('cartItems: ' + JSON.stringify(cartItems));
      for (let i = 0; i < cartItems.length; i++) {
        let cartItem = cartItems[i];
        for(let x = 1; x <= cartItem.count; x++){
          let refundCartItem = {
            id: cartItem.id + '_' + x, //make it unique
            itemId: cartItem.itemId,
            itemName: cartItem.itemName,
            itemType: cartItem.itemType,
            price: cartItem.price,
            discountPercent: cartItem.discountPercent ? cartItem.discountPercent : 0,
            count: 1,
            itemSubselection: cartItem.itemSubselection,
            spicyLevel: cartItem.spicyLevel,
            comment: cartItem.comment,
            createdDate: cartItem.createdDate
          };
          refundCartItems.push(refundCartItem);

          //subselection addOns
          if(refundCartItem.itemSubselection){
            let subselectionAddOnCartItems =  refundCartItem.itemSubselection.addOnCartItems;
            if(subselectionAddOnCartItems){
              for(let a = 0; a < subselectionAddOnCartItems.length; a++){
                let addOnCartItem = subselectionAddOnCartItems[a];
                let addOn = addOnCartItem.addOn;
                for(let y = 1; y <= addOnCartItem.count; y++){
                  //Only if it isn't free
                  if(addOnCartItem.price > 0){
                    let addOnRefundCartItem = {
                      id: cartItem.id + '_' + x + '_' + addOnCartItem.addOnId + '_' + y, //make it unique
                      itemId: addOnCartItem.addOnId,
                      itemName: addOn.name,
                      itemType: 'ADD_ON_OF_ADD_ON_GROUP',
                      price: addOnCartItem.price,
                      discountPercent: cartItem.discountPercent ? cartItem.discountPercent : 0, //The same as cartItem.discountPercent
                      count: 1,
                      createdDate: cartItem.createdDate
                    };
                    refundCartItems.push(addOnRefundCartItem);
                  }
                }
              }
            }
          }

          //item addOns
          if(cartItem.addOnCartItems && cartItem.addOnCartItems.length > 0){
            for (let a = 0; a < cartItem.addOnCartItems.length; a++) {
              let addOnCartItem = cartItem.addOnCartItems[a];
              let addOn = addOnCartItem.addOn;
              for(let y = 1; y <= addOnCartItem.count; y++){
                //Only if it isn't free
                if(addOnCartItem.price > 0){
                  let addOnRefundCartItem = {
                    id: cartItem.id + '_' + x + '_' + addOnCartItem.addOnId + '_' + y, //make it unique
                    itemId: addOnCartItem.addOnId,
                    itemName: addOn.name,
                    itemType: 'ADD_ON_OF_ADD_ON_GROUP',
                    price: addOnCartItem.price,
                    discountPercent: cartItem.discountPercent ? cartItem.discountPercent : 0, //The same as cartItem.discountPercent
                    count: 1,
                    createdDate: cartItem.createdDate
                  };
                  refundCartItems.push(addOnRefundCartItem);
                }
              }
            }
          }
        }
      }
      return refundCartItems;
    },

    getOrderTypeText(){
      return OrderUtil.getOrderTypeText(this.order.orderType).toUpperCase();
    },
    getPriceWithVat(cartItem){
      let vatRate = VatUtil.getVatRate(this.vat, this.order.orderType, cartItem.itemType);
      let priceWithVat = OrderUtil.getPriceWithVat(vatRate, cartItem.price) * (100 - cartItem.discountPercent) / 100;
      if(cartItem.discountPercent == 0){
        return Math.round(priceWithVat); //make it exact as it is in order description.
      } else {
        return MathUtil.roundTwoDecimals(priceWithVat);
      }
    },
    disableNextBtn(){
      let disabled = this.selectedCartItems.length == 0;
      if(this.order.tipsAmount && this.order.tipsAmount > 0 && disabled){
        if(this.refundedTipsAmount == 0){
          disabled = true;
        } else {
          disabled = false;
        }
      }
      return disabled;
    },
    selectAllOrRemoveAllButtonText(){
      if(this.order.tipsAmount && this.order.tipsAmount > 0){
        if(this.selectedCartItems.length == this.refundCartItems.length && this.refundedTipsAmount > 0){
          return 'Fjern alle valgte';
        }else {
          return 'Velg alle';
        }
      } else {
        if(this.selectedCartItems.length == this.refundCartItems.length){
          return 'Fjern alle valgte';
        }else {
          return 'Velg alle';
        }
      }
    },
    selectAllOrRemoveAll(){
      //console.log('BEFORE: ' + JSON.stringify(this.selectedCartItems));
      if(this.selectedCartItems.length == this.refundCartItems.length){
        //Remove all
        this.selectedCartItems = [];
        this.refundedTipsAmount = 0;
      }else {
        //Add all
        for (let i = 0; i < this.refundCartItems.length; i++) {
          if(!this.selectedCartItemsContains(this.refundCartItems[i])){
            this.selectedCartItems.push(this.refundCartItems[i]);
          }
        }
        this.refundedTipsAmount = this.order.tipsAmount ? this.order.tipsAmount : 0;
      }
      //console.log('AFTER: ' + JSON.stringify(this.selectedCartItems));
    },
    updateRefundedTipsAmount(){
      if(this.refundedTipsAmount > 0){
        this.refundedTipsAmount = 0;
      } else {
        this.refundedTipsAmount = this.order.tipsAmount;
      }
    },
    updateSelectedCartItems(refundCartItem){
      console.log('BEFORE: ' + JSON.stringify(this.selectedCartItems));
      if(this.selectedCartItemsContains(refundCartItem)){
        //Already selected, remove it
        for (let i = 0; i < this.selectedCartItems.length; i++) {
          if (this.selectedCartItems[i].id == refundCartItem.id) {
            this.selectedCartItems.splice(i, 1);
            break;
          }
        }
      }else {
        //Add it
        this.selectedCartItems.push(refundCartItem);
      }
      console.log('AFTER: ' + JSON.stringify(this.selectedCartItems));
    },
    selectedCartItemsContains(cartItem){
      for (let i = 0; i < this.selectedCartItems.length; i++) {
        let selectedCartItem = this.selectedCartItems[i];
        if(selectedCartItem.id == cartItem.id){
          return true;
        }
      }
      return false;
    },
    getTotalRefundAmount(){
      let totalPricesInCart = 0.00;
      for (let i = 0; i < this.selectedCartItems.length; i++) {
        let selectedCartItem = this.selectedCartItems[i];
        let cartItemPrice = this.getPriceWithVat(selectedCartItem);
        console.log(cartItemPrice);
        totalPricesInCart = totalPricesInCart + cartItemPrice;
      }

      totalPricesInCart = totalPricesInCart + this.refundedTipsAmount;
      return totalPricesInCart;
    },
    getTransactionId(){
      let payments = this.order.payments;
      for (let i = 0; i < payments.length; i++) {
        let payment = payments[i];
        if(payment.transactionId){
          return payment.transactionId;
        }
      }
      return null;
    },
    async refund(){
      const ok = await this.$refs.confirmDialogue.show({
        title: 'Refundere kr ' + this.displayTwoDecimals(this.getTotalRefundAmount()),
        message: 'Vil du refundere kr ' + this.displayTwoDecimals(this.getTotalRefundAmount()) + ' til kunden?',
        okButton: 'Refunder',
        cancelButton: 'Avbryt',
      })
      if(ok) {
        this.loading = true;
        if(this.getOnlinePaymentProvider() == 'SOFTPAY'){
          this.openSoftpayAppOnDevice(this.order.orderId, this.getTotalRefundAmount());
        }
      } else {
        //Cancel, do nothing
        console.log('OnlinePaymentProvider NOT supported');
      }
    },
    openSoftpayAppOnDevice(orderId, totalRefundAmount){
      if(window.JSBridge && window.JSBridge.openSoftpayApp) {
        window.JSBridge.openSoftpayApp(orderId, totalRefundAmount);
      }
    },
    refundOrder(){
      SoftpayService.refund(this.getTransactionId(), this.getTotalRefundAmount(), this.selectedCartItems, this.refundedTipsAmount, this.orderDocumentId).then(
          (response) => {
            if(response.data === 'SUCCEEDED'){
              this.goToOrderDetailPage();
            }else {
              this.showUpdatingOrderFailedMessage();
              this.loading = false;
            }
          },
          (error) => {
            console.log(error);
          }
      );
    },
    goToOrderDetailPage(){
      this.$router.push({ path: 'orderDetail', query: {
          orderDocumentId: this.orderDocumentId,
          showRefundInfo: 'false'
        }
      });
    },
    async showRefundFailedMessage(){
      const ok = await this.$refs.confirmDialogue.show({
        title: 'Refusjonen mislykket',
        message: 'Det skjedde en feil og refusjonen ble ikke fullført.',
        okButton: 'OK',
        cancelButton: 'NOT_SET'
      })
      if(ok) {
        //Do nothing
      }
    },
    async showUpdatingOrderFailedMessage(){
      const ok = await this.$refs.confirmDialogue.show({
        title: 'Refusjonen vellykket',
        message: 'Refusjonen ble fullført. Men det skjedde en feil ved oppdatering av ordren i systemet. Vennligst kontakt Cagista Support.',
        okButton: 'OK',
        cancelButton: 'NOT_SET'
      })
      if(ok) {
        //Do nothing
      }
    },
    displayTwoDecimals(num){
      if(num > 0 || num < 0){
        return MathUtil.displayTwoDecimals(num);
      }else {
        return num;
      }
    }
  }
}
</script>

<style scoped>
  .refundCartItem{
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: #CCCCCC;
    border-bottom: 1px  solid #CCCCCC;
  }

  .checkbox{
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }
</style>
