<template>
  <LoadingSpinner :loading='loading'> </LoadingSpinner>

  <div class="container mt-4 mb-4">
    <div style="max-width:560px">
        <h3>SwedbankPay eCom konto</h3>
        <div v-if='updated'>
          <span class='message-info'>SwedbankPay eCom kontoinnstillingene er oppdatert</span>
        </div>

        <div v-if="hasMultiLocations()" class="select-location-box mt-4">
          <span class="ingredient-title">For avdeling</span>
          <select class="form-select" v-model="locationId" @change="getSwedbankPayAccount()">
            <option v-for="(location, index) in locations" :key="index" :value="location.id">{{ location.name }}</option>
          </select>
        </div>

        <div class="mt-4">
          <span class='ingredient-title'>swedbankPayBaseApiUrl</span>
          <span>TEST: https://api.externalintegration.payex.com</span> <br/>
          <span>PROD: https://api.payex.com</span>
          <input class='form-control' type='text' v-model='swedbankPayAccount.swedbankPayBaseApiUrl' />
        </div>

        <div class="mt-4">
          <span class='ingredient-title'>payeeId (f.eks: ee732b69-f8d3-4ebe-aa8f-26312ae602b2)</span>
          <input class='form-control' type='text' v-model='swedbankPayAccount.payeeId' />
        </div>

        <div>
          <span class='ingredient-title'>payeeName (f.eks: TastyKitchen AS)</span>
          <input class='form-control' type='text' v-model='swedbankPayAccount.payeeName' />
        </div>

        <div>
          <span class='ingredient-title'>hostUrl (f.eks: https://tastykitchen.no)</span>
          <input class='form-control' type='text' v-model='swedbankPayAccount.hostUrl' />
        </div>

        <div>
          <span class='ingredient-title'>cancelUrl (f.eks: https://tastykitchen.no/oslo)</span>
          <input class='form-control' type='text' v-model='swedbankPayAccount.cancelUrl' />
        </div>

        <div>
          <span class='ingredient-title'>completeUrl (f.eks: https://tastykitchen.no/oslo/swedbankPayPaymentStatus)</span>
          <input class='form-control' type='text' v-model='swedbankPayAccount.completeUrl' />
        </div>

        <div>
          <span class='ingredient-title'>primary (theme color f.eks: #f0c7a9)</span>
          <input class='form-control' type='text' v-model='swedbankPayAccount.themePrimaryColor' />
        </div>

        <div>
          <span class='ingredient-title'>Access Token</span>
          <input class='form-control' type='text' v-model='swedbankPayAccount.accessToken' />
        </div>

        <div class="mt-3" style="font-style: italic">
          <span>NB! Access Token er gyldig i 10 år.</span>
        </div>

        <div class='bo_hr mt-4'>
          <hr/>
        </div>

        <div class='mb-5'>
          <button v-if='activateSaveButton' type='button' class='bo_btn' @click='save'> Lagre </button>
          <button v-else type='button' class='bo_btn' @click='save' disabled='true'> Lagre </button>
        </div>
    </div>
  </div>
</template>

<script>
  import SettingsService from '@/services/settings/SettingsService';
  import MerchantUtil from "@/util/MerchantUtil";
  import LoadingSpinner from "@/components/other/LoadingSpinner";
  import LocationUtil from "@/util/LocationUtil";
  import LocationService from "@/services/location/LocationService";

  export default {
    components: {LoadingSpinner},
    data() {
      return {
        loading: true,
        locationId: '',
        locations: [],

        updated: false,
        swedbankPayAccount: {
          type: "swedbankpay_account",
          merchant: '',
          locationId: '',
          swedbankPayBaseApiUrl: '',
          payeeId: '',
          payeeName: '',
          hostUrl: '',
          cancelUrl: '',
          completeUrl: '',
          themePrimaryColor: '',
          accessToken: ''
        }
      };
    },
    mounted() {
      LocationService.listLocations().then(
          (response) => {
            this.locations = response.data;
            this.locationId = this.locations.length ? this.locations[0].id : '';

            this.getSwedbankPayAccount();
          },
          (error) => {
            console.log(error);
          }
      );
    },
    computed: {
      activateSaveButton(){
        if(this.swedbankPayAccount.swedbankPayBaseApiUrl.length > 0 && this.swedbankPayAccount.payeeId.length > 0 && this.swedbankPayAccount.payeeName.length > 0 &&
            this.swedbankPayAccount.hostUrl.length > 0 && this.swedbankPayAccount.cancelUrl.length > 0 && this.swedbankPayAccount.completeUrl.length > 0 &&
            this.swedbankPayAccount.themePrimaryColor.length > 0 && this.swedbankPayAccount.accessToken.length > 0){
          return true;
        } else {
          return false;
        }
      }
    },
    methods: {
      hasMultiLocations(){
        return LocationUtil.hasMultiLocations();
      },
      getSwedbankPayAccount(){
        SettingsService.getSwedbankPayAccount(this.locationId).then(
            (response) => {
              if(response.data !== null){
                this.swedbankPayAccount = response.data;
              }

              this.loading = false;
            },
            (error) => {
              console.log(error);
            }
        );
      },
      save(){
        this.loading = true;
        this.swedbankPayAccount.merchant = MerchantUtil.getMerchant();
        this.swedbankPayAccount.locationId = this.locationId;

        SettingsService.editSwedbankPayAccount(this.swedbankPayAccount).then(
            (response) => {
              let documentId = response.data;
              this.swedbankPayAccount.id = documentId;
              if(documentId){
                this.updated = true;
              } else {
                console.log("Editing swedbankPayAccount failed");
              }
              this.loading = false;
            },
            (error) => {
              console.log(error);
            }
        );
      }
    }
  };
</script>
