import authHeader from "@/services/auth/AuthHeader";
import axios from "axios";
import MathUtil from "@/util/MathUtil";

class SwedbankPayService {
    createAmountForSwedbankPay(amount){
        let amountForDintero = MathUtil.displayTwoDecimals(amount).replace(',', ''); //Just remove comma
        return parseInt(amountForDintero);
    }

    createItemDescription(cartItem){
        var description = cartItem.itemName;
        if(cartItem.selectedItemSubselection){
            description += ', ' + cartItem.selectedItemSubselection;
        }
        if(cartItem.spicyLevel){
            description += ', ' + cartItem.spicyLevel;
        }
        if(cartItem.comment){
            description += ', ' + cartItem.comment;
        }
        return description;
    }

    createSwedbankPayItems(cartItems){
        var swedbankPayItems = [];
        for (let i = 0; i < cartItems.length; i++) {
            let cartItem = cartItems[i];
            let lineId = i + 1;

            let swedbankPayItem = {};
            swedbankPayItem.id = '...';
            swedbankPayItem.line_id = lineId.toString();

            swedbankPayItem.quantity = cartItem.count;
            swedbankPayItem.amount = this.createAmountForSwedbankPay(parseFloat(cartItem.price) * cartItem.count);
            swedbankPayItem.description = this.createItemDescription(cartItem);
            swedbankPayItems.push(swedbankPayItem);
        }

        //console.info('orderItems: ' + JSON.stringify(dinteroItems));
        return swedbankPayItems;
    }

    refund(paymentOrderId, amount, cartItems, refundedTipsAmount, orderDocumentId){
        //console.log('refunded cartItems: ' + JSON.stringify(cartItems));
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/payment/swedbankPayRefund';
        let basicAuth = authHeader();
        return axios.post(url,
            {
                merchant: user.merchant,
                paymentOrderId: paymentOrderId,
                amount: this.createAmountForSwedbankPay(amount),
                swedbankPayItems: this.createSwedbankPayItems(cartItems),
                cartItems: cartItems,
                refundedTipsAmount : refundedTipsAmount,
                orderDocumentId: orderDocumentId
            },
            {headers: basicAuth });
    }
}

export default new SwedbankPayService();
