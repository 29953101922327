<template>
  <div class="container">
      <LoadingSpinner :loading="loading"> </LoadingSpinner>

      <div class="mt-4" style="max-width:545px">
        <h3>Omsetningsrapport</h3>

        <div v-if="!loading">
            <div v-if="hasMultiLocations()" class="mt-4">
              <span class="ingredient-title">Velg avdeling</span>
              <select class="form-select" v-model="locationId" @change="showTerminals()">
                <option v-for="(location, index) in locations" :key="index" :value="location.id">{{ location.name }}</option>
              </select>
            </div>

            <div v-if="terminals.length > 0">
                <SelectTerminal :terminals="terminals" :terminal="selectedTerminal" @setSelectedTerminal="setSelectedTerminal"/>

                <SelectDate ref="selectDateFrom" label="Velg fra og med dato"/>
                <SelectDate ref="selectDateTo" label="Velg til og med dato"/>

                <button type="button" class="bo_btn mt-3" :class="zReports.length == 0 ? 'mb-5' : 'mb-0'" @click="showTotalSalesReport"> Vis omsetningsrapport </button>

                <div v-if="zReports.length == 0 && resultFetched" class="xz-report mb-5">
                  <p class="no-report-found">Ingen omsetning.</p>
                </div>

                <ShowTurnoverReport v-if="zReports.length > 0"
                                    :zReports="zReports"
                                    :vat="vat"
                                    :company="company"
                                    :location="getLocation()"
                                    :terminal="selectedTerminal"
                                    :fromDate="fromDate"
                                    :toDate="toDate" />
            </div>
        </div>
      </div>
  </div>
</template>

<script>
  import LoadingSpinner from "@/components/other/LoadingSpinner";
  import SelectDate from "@/components/other/SelectDate";
  import ShowTurnoverReport from "@/components/report/turnover/ShowTurnoverReport";
  import ReportService from "@/services/report/ReportService";
  import LocationUtil from "@/util/LocationUtil";
  import MerchantUtil from "@/util/MerchantUtil";
  import TerminalService from "@/services/terminal/TerminalService";
  import SelectTerminal from "@/components/report/SelectTerminal";

  export default {
    components: {LoadingSpinner, SelectTerminal, SelectDate, ShowTurnoverReport},
    data() {
      return {
        loading: true,

        locationId: '',
        locations: [],
        merchant: '',
        company: {},
        terminals: [],
        selectedTerminal: {},
        vat: {},
        fromDate: new Date(),
        toDate: new Date(),
        zReports: [],
        resultFetched: false
      };
    },
    mounted() {
      ReportService.getDataForReportGui().then(
          (response) => {
            let dataForGui = response.data;
            //console.log("dataForGui: " +  JSON.stringify(dataForGui));
            this.merchant = dataForGui.merchant;
            this.company = dataForGui.company;
            this.locations = dataForGui.locations;
            this.vat = dataForGui.vat;

            this.locationId = this.locations.length ? this.locations[0].id : '';

            this.showTerminals();
          },
          (error) => {
            console.log(error);
          }
      );
    },
    methods: {
      hasMultiLocations(){
        return LocationUtil.hasMultiLocations();
      },
      showTerminals() {
        this.loading = true;
        this.zReports = []; //reset it
        this.resultFetched = false;

        TerminalService.getTerminalsForLocation(this.locationId).then(
            (response) => {
              let filteredTerminals = [];

              let availableTerminals = response.data;
              if(availableTerminals.length > 0){
                let all = {
                  terminalId: 'ALL',
                  locationId: this.locationId
                };
                filteredTerminals.push(all);
              }

              for(let i = 0; i < availableTerminals.length; i++) {
                let terminal = availableTerminals[i];
                if(terminal.terminalType != 'KDS'){
                  filteredTerminals.push(terminal);
                }
              }

              if(this.useFunctionality() === 'TAKEAWAY' || this.useFunctionality() === 'ALL'){
                let webshop = {
                  terminalId: 'WEBSHOP',
                  locationId: this.locationId
                };
                filteredTerminals.push(webshop);
              }

              this.terminals = filteredTerminals;
              this.selectedTerminal = this.terminals.length > 0 ? this.terminals[0] : {};

              this.loading = false;
            },
            (error) => {
              console.log(error);
            }
        );
      },
      setSelectedTerminal(terminal){
        this.selectedTerminal = terminal;
        this.zReports = []; //reset it
        this.resultFetched = false;
      },
      useFunctionality(){
        return MerchantUtil.useFunctionality();
      },
      getLocation(){
        return LocationUtil.getLocation(this.locationId);
      },
      showTotalSalesReport() {
        this.loading = true;
        let fromDate =  this.$refs.selectDateFrom.getSelectedDate();
        let toDate =  this.$refs.selectDateTo.getSelectedDate();
        this.fromDate = fromDate;
        this.toDate = toDate;

        ReportService.getTotalSalesReport(this.locationId, this.selectedTerminal.terminalId, fromDate, toDate).then(
            (response) => {
              if(response.data){
                //console.log(response.data.result);
                  this.zReports =response.data;
              } else {
                  this.zReports = [];
              }

              this.loading = false;
              this.resultFetched = true;
            },
            (error) => {
              console.log(error);
            }
        );
      }
    }
  };
</script>
