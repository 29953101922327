import axios from "axios";
import authHeader from '@/services/auth/AuthHeader';

class ReportService {
    getDataForReportGui(){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/report/getDataForReportGui' + '?merchant=' + user.merchant;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    getXReportCounters(locationId, terminalId){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/report/getXReportCounters' + '?merchant=' + user.merchant + '&locationId=' + locationId + '&terminalId=' + terminalId;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    getXReportCountersForDate(locationId, terminalId, date){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/report/getXReportCountersForDate' + '?merchant=' + user.merchant + '&locationId=' + locationId + '&terminalId=' + terminalId + '&date=' + date;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    getZReport(locationId, terminalId, selectedDate){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/report/getZReport' + '?merchant=' + user.merchant + '&locationId=' + locationId + '&terminalId=' + terminalId + '&date=' + selectedDate;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    getWebshopXReportCounters(locationId){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/report/getWebshopXReportCounters' + '?merchant=' + user.merchant + '&locationId=' + locationId;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    getWebshopXReportCountersForDate(locationId, date){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/report/getWebshopXReportCountersForDate' + '?merchant=' + user.merchant + '&locationId=' + locationId + '&date=' + date;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    createWebshopZReport(locationId){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/report/createWebshopZReport' + '?merchant=' + user.merchant + '&locationId=' + locationId;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    createZReport(locationId, terminalId){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/report/createZReport' + '?merchant=' + user.merchant + '&locationId=' + locationId + '&terminalId=' + terminalId;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    createZReportForDate(locationId, terminalId, date){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/report/createZReportForDate' + '?merchant=' + user.merchant + '&locationId=' + locationId + '&terminalId=' + terminalId + '&date=' + date;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    createWebshopZReportForDate(locationId, date){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/report/createWebshopZReportForDate' + '?merchant=' + user.merchant + '&locationId=' + locationId + '&date=' + date;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    createAllWebshopZReports(locationId, date){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/report/createAllWebshopZReports' + '?merchant=' + user.merchant + '&locationId=' + locationId + '&fromDate=' + date;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    getWebshopZReport(locationId, selectedDate){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/report/getWebshopZReport' + '?merchant=' + user.merchant + '&locationId=' + locationId + '&date=' + selectedDate;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    getTotalSalesReport(locationId, terminalId, fromDate, toDate){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/report/getTotalSalesReport' + '?merchant=' + user.merchant + '&locationId=' + locationId + '&terminalId=' + terminalId + '&fromDate=' + fromDate + '&toDate=' + toDate;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    getSmsReport(locationId, fromDate, toDate){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/report/getSmsReport' + '?merchant=' + user.merchant + '&locationId=' + locationId + '&fromDate=' + fromDate + '&toDate=' + toDate;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }
}

export default new ReportService();
